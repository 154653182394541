import * as React from 'react';
import { Item } from '../../../../../../components/Grid';
import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';
import { formatSeizureClassification } from '../../../../../Seizure/utils';
import InfoPopover from 'Components/InfoPopover';

const isISeizureType = (obj: unknown): obj is ISeizureType => {
  const doc = obj as { _type?: string };
  return !!(doc._type && doc._type === 'seizureType');
};

const _SeizureTypeHistory = (props: IHistoryContext): JSX.Element => {
  const { startEdit, fm, documents } = props;
  const docs = documents?.filter((d: any) => d._type === 'seizureType');
  const seizureTypeDocs: ISeizureType[] = docs?.every((d) => isISeizureType(d)) ? docs : [];
  const prefix = 'diagnosis.epilepsy.seizureType.'; // for localization

  const makeRow = (doc: ISeizureType): JSX.Element => (
    <>
      <Item xs={4}>{doc.freeDescription ?? '-'}</Item>

      <Item xs={3}>{doc.nameByPatient ?? '-'}</Item>
      <Item xs={3}>
        {doc.classification && doc.classification.classification
          ? formatSeizureClassification(doc.classification?.classification as TSeizureClassification, 'long', true)
          : '-'}
      </Item>

      <Item xs={2}>
        {doc.firstOccurenceDate
          ? formatPartialDate(doc.firstOccurenceDate) !== ''
            ? formatPartialDate(doc.firstOccurenceDate)
            : doc.firstOccurenceDate.filter((n) => n).join('/')
          : '-'}
      </Item>
    </>
  );
  return (
    <React.Fragment>
      <HistoryRow
        headerText={fm && fm('diagnosis.epilepsy.seizureType.title')}
        rowButton={startEdit ? newButton('seizureType', startEdit({}, 'seizureType'), 'general.new', 15) : undefined}
      >
        <HistoryRowListing
          headers={
            <>
              <Item xs={4}>{fm && fm(prefix + 'seizureFreeDescription')}</Item>

              <Item xs={3}>
                {fm && fm(prefix + 'seizureNameByPatient')}
                <span style={{ marginLeft: '1rem' }}>
                  <InfoPopover text="diagnosis.epilepsy.seizureType.seizureNameByPatientInfotext" color="primary" />
                </span>
              </Item>
              <Item xs={3}>{fm && fm(prefix + 'seizureClassification')}</Item>

              <Item xs={2}>{fm && fm(prefix + 'firstOccurenceDate')}</Item>
            </>
          }
          contentFormat={makeRow}
          documents={seizureTypeDocs.length > 0 ? seizureTypeDocs : undefined}
          dateFieldName={'firstOccurenceDate'}
          collapse={false}
          makeControls
          historyRowControls={(d: IControlProps) =>
            startEdit ? <HistoryRowControls document={d} startEdit={startEdit} /> : <></>
          }
        />
      </HistoryRow>
    </React.Fragment>
  );
};

/** Component rendering epilepsy platform's seizure type docs' summary. */
const SeizureTypeHistory = withHistoryContext(_SeizureTypeHistory);
export default SeizureTypeHistory;
