import * as React from 'react';
import { useAppSelector, useAppSelector as useSelector } from 'Store/index';
import { getDiseaseModifyingMedications } from '../utils';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { intersperse } from 'ramda';
import { sortDocuments } from '../../../utility/randomUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import MedicationTile from 'Components/DashboardTile/TileContentMaker/components/MedicationTile';

// Return latest SMA/DMD medication sorted its administration dates
const findLatestSmaDmdMedication = (platform: string, documents: IMedication[]): IMedication[] => {
  /** Medications visualized in tile are different from graph, so define them here */
  const atcCodesToVisualize: { [key: string]: string[] } = {
    sma: [
      'M09AX07', // nusinerseeni
      'M09AX09', // onasemnogeeniabeparvoveekki
      'M09AX10', // risdiplaami
    ],
    dmd: [
      'M09AX03', // atalureeni
    ],
  };

  const medications = (documents ?? []).filter((document) => {
    return atcCodesToVisualize[platform].includes(document.atc ?? '');
  });

  return medications;
};

const GeneralTile = ({ documents }: { documents: Array<IMedication>; platform: Platform }): JSX.Element => {
  const platformMedications: IMedication[] =
    documents && documents.length > 0 ? documents.filter((d) => d.hasEnded?.[0] !== true) : [];
  const platformMedicationsEnded: IMedication[] =
    documents && documents.length > 0 ? documents.filter((d) => d.hasEnded?.[0] === true) : [];

  const latestMedication = platformMedications.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'startDate' }])(a, b),
  )[0];
  const latestMedicationEnded = platformMedicationsEnded.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'endDate' }])(a, b),
  )[0];

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const dataPicker = (document: IMedication) => {
    const dateFieldName = isPartialDate(document?.usageStartDate) ? 'usageStartDate' : 'startDate';
    const dateValue = document?.[dateFieldName];
    return {
      title: fm(document.hasEnded?.[0] === true ? 'medication.tileEnded' : 'medication.tileStarted'),
      medicationName: document.medicationName,
      date: isPartialDate(dateValue) ? formatPartialDate(dateValue) : null,
      endDate:
        isPartialDate(document.endDate) && document.hasEnded?.[0] === true ? formatPartialDate(document.endDate) : null,
      endReasons: intersperse(
        ', ',
        (document.endReason || []).map((reason) => fm('medication.opts.' + reason)),
      ),
      key: document._id,
    };
  };

  return (
    <React.Fragment>
      {latestMedication || latestMedicationEnded ? (
        <MedicationTile
          data={[
            ...(latestMedication ? [dataPicker(latestMedication)] : []),
            ...(latestMedicationEnded ? [dataPicker(latestMedicationEnded)] : []),
          ]}
        />
      ) : (
        <DescriptionText>
          <FormattedMessage id="medication.noDiseaseModifyingMedications" />
        </DescriptionText>
      )}
    </React.Fragment>
  );
};

const MedicationDash = ({ documents: documentsRedux }: IOwnProps): JSX.Element | null => {
  const documents: IMedication[] | undefined = JSON.parse(JSON.stringify(documentsRedux));

  // Only use documents owned by current organization
  const orgId = useSelector((s: IState) => s.session.data?.orgid);
  const selectedMedicationOrg = useSelector((s: IState) => s.session.selectedMedicationOrg) || orgId;
  const thisOrgDocuments = (documents || []).filter(
    (d) =>
      d._ownerOrg === selectedMedicationOrg ||
      (d._pastOwners && Object.keys(d._pastOwners).some((org) => org === selectedMedicationOrg)),
  );
  const platform = useAppSelector((s) => s.session.platforms?.selected);

  return thisOrgDocuments && platform ? (
    <React.Fragment>
      <GeneralTile
        documents={
          platform === 'sma' || platform === 'dmd'
            ? findLatestSmaDmdMedication(platform, thisOrgDocuments || [])
            : getDiseaseModifyingMedications(thisOrgDocuments || [], platform)
        }
        platform={platform}
      />
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  documents?: IMedication[];
}

export default MedicationDash;
