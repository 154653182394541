import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { bai, isLocaleKey, IBAI, Task_Progress } from 'neuro-schemas';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { omit } from 'ramda';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import DataTable from 'Components/DataTable';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { formatPartialDate } from 'neuro-utils';

/**
 * Formats a string with tags to include formatted text as elements
 * @param {string} str - Text string
 * @param {'b'} tag - Tag to be replaced
 * @returns {JSX.Element} Formatted text element
 */
const replaceTags = (str: string, tag = 'b') => {
  const regExpSplit = RegExp(`<${tag}>.+</${tag}>`);
  const normalText = str.split(regExpSplit);

  const regExpMatch = RegExp(`<${tag}>(.+)</${tag}>`);
  const matches = str.match(regExpMatch);
  matches && matches.shift();

  const returnElement = (
    <>
      {normalText.map((n, i) => (
        <React.Fragment key={n}>
          {n}
          {matches?.[i] && <span style={tag === 'b' ? { fontWeight: 600 } : {}}>{matches[i]}</span>}
        </React.Fragment>
      ))}
    </>
  );
  return returnElement;
};

const omitControlData = (doc: IBAI) => {
  let data = omitControlProps(doc);
  data = omit(['edited'], data);
  return data;
};

const BAIScore = ({ baiData, fm }: { baiData: IBAI & IControlProps; fm: (s: string) => string }): JSX.Element => {
  const score = Task_Progress.calculateProgress('bai', baiData).yielded;
  const localization = score ? fm(`myService.ninmt.baiAnxietyDescs.${bai.calculators.baiAnxietyDesc(score)}`) : null;
  return (
    <>
      <span style={{ fontWeight: 600 }}>{score ? score + ' ' : '-'}</span>
      <span>{localization}</span>
    </>
  );
};

export const Bai = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const baiData = (editing?.data || viewing?.data || {}) as IBAI & IControlProps;

  const baiQuestions = bai.surveyGenerator.questions();
  const baiLocales = bai.localizations;

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...baiData,
      [field]: typeof value === 'string' ? parseInt(value) : value,
    });
  };
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const isEditing = !viewing && !!editing;

  const baiQuestion = (id: string) => baiQuestions.find((bdiq) => bdiq.id === id);

  const baiOption = (id: string) =>
    baiQuestions.find((bdiq) => bdiq.id === id)?.options?.[(baiData as Record<string, any>)[id]];

  const questionLocalized = (qid: string) => {
    const localeString = baiOption(qid)?.optionLocale;
    return localeString && baiLocales[useLocale][localeString];
  };

  if (viewing) {
    return (
      <>
        <BlockWrapper
          title={formatPartialDate(baiData.date)}
          buttons={[
            {
              title: 'general.edit',
              onClick: () => {
                setViewingObj(null);
                setEditingObj({ type: 'bai', data: baiData });
              },
            },
          ]}
        >
          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <DataTable
              headers={['question', 'answer', 'score']}
              headersFormatter={(s: string) => fm('myService.' + s)}
              data={{
                rowData: Object.keys(omitControlData(baiData))
                  .filter((q) => q !== 'date')
                  .map((q) => [
                    baiLocales[useLocale][baiQuestion(q)?.questionLocale || ''],
                    <span key={q + 'long'} style={{ fontWeight: 600, color: colors.black }}>{`${questionLocalized(
                      q,
                    )}`}</span>,
                    <span key={q + 'short'} style={{ fontWeight: 600, color: colors.black }}>{`${baiOption(q)
                      ?.value}`}</span>,
                  ]),
              }}
            />
          </div>
          <QuestionAnswerPair question={fm('myService.score')} answer={<BAIScore baiData={baiData} fm={fm} />} />
        </BlockWrapper>
      </>
    );
  }

  return (
    <>
      <div style={{ fontStyle: 'italic', marginBottom: '2rem', color: colors.tertiaryText }}>
        <span style={{ fontWeight: 600 }}>{`${baiLocales[useLocale][
          baiQuestions[0].questionLocale ?? '-'
        ].toUpperCase()}: `}</span>
        <span>{replaceTags(baiLocales[useLocale][baiQuestions[0].infoTextLocale ?? '-'])}</span>
      </div>
      <FormSection>
        <>
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              editing={isEditing}
              isNotCancellable={true}
              formData={{
                document: { date: baiData?.date },
                onChange: onChangeSurveyForm,
              }}
              dateDefault="now"
            />
          </FormRow>
          {baiQuestions.map((q) => {
            const getOptionLocale = (o: string | number) => q.options?.find((opt) => opt.value === o)?.optionLocale;

            if (q.id === 'startInfo') return <React.Fragment key={q.id}></React.Fragment>;
            return (
              <FormRow key={`${q.id}`} title={baiLocales[useLocale][q.questionLocale || '']} formatTitle={false}>
                <InputHandler
                  type="Radio"
                  name={q.id}
                  options={q.options?.map((o) => o.value)}
                  editing={isEditing}
                  optionFormatter={(o) => baiLocales[useLocale][getOptionLocale(o) || '']}
                  formData={{
                    document: { [q.id]: baiData?.[q.id as keyof IBAI] },
                    onChange: onChangeSurveyForm,
                  }}
                />
              </FormRow>
            );
          })}
          <FormRow
            title={'myService.ninmt.score'}
            description={fm('myService.ninmt.baiTotalScoreDescription', { br: <br /> })}
          >
            <BAIScore baiData={baiData} fm={fm} />
          </FormRow>
        </>
      </FormSection>
    </>
  );
};
