import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { isLocaleKey, IMdsUpdrsIV, mdsUpdrsIV } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { Theme, useMediaQuery } from '@mui/material';
import FormSection from 'Components/FormSection';

const StyledInfo = ({ mdOrSmaller, children }: { mdOrSmaller: boolean; children: JSX.Element }): JSX.Element => {
  return (
    <div
      style={{
        width: mdOrSmaller ? '100%' : '80%',
        color: colors.tertiaryText,
        marginBottom: '3rem',
        fontStyle: 'italic',
      }}
    >
      {children}
    </div>
  );
};

const StyledQuestionAndInfo = ({
  field,
  locales,
}: {
  field: string;
  locales: {
    [key: string]: string;
  };
}) => {
  let instruction;
  if (field === 'complexityOfMotorFluctuations') {
    // This type of text styling only works as long as EN and FI localizations are same
    const instArr: Array<string | JSX.Element> = locales[`${field}InfoPatient`].split(' ');
    [49, 57].forEach((idx) => {
      instArr.splice(idx, 1, <span style={{ textDecoration: 'underline' }}>{instArr[idx]}</span>);
    });
    instArr.splice(65, 2, <span style={{ textDecoration: 'underline' }}>{instArr[65] + ' ' + instArr[66]}</span>);
    instruction = instArr;
  } else {
    instruction = locales[`${field}InfoPatient`];
  }

  return (
    <div>
      <p style={{ fontWeight: 600 }}>{locales[field]}</p>
      <p>{locales[`${field}InfoExaminer`]}</p>
      <p style={{ fontStyle: 'italic' }}>
        {Array.isArray(instruction)
          ? instruction.map((inst, i) => (
              <React.Fragment key={`${inst}${i}`}>
                <span> </span>
                {inst}
                <span> </span>
              </React.Fragment>
            ))
          : instruction}
      </p>
    </div>
  );
};

const optionFormatter = (
  o: string | number,
  field: string,
  locales: {
    [key: string]: string;
  },
): string | JSX.Element => {
  if (o === 'UR') return o;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ whiteSpace: 'nowrap', minWidth: '10rem' }}>
        {`${o}: ${locales[`opts.${o}`]}`}
        {': '}
      </div>
      <div>{locales[`opts.${field}.${o}`]}</div>
    </div>
  );
};

const getMdsUpdrsIVScore = (percentage: number): 1 | 2 | 3 | 4 => {
  if (percentage <= 25) return 1;
  if (percentage <= 50) return 2;
  if (percentage <= 75) return 3;
  return 4;
};

export const MdsUpdrsIV = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const mdsUpdrsIVData = (editing?.data || viewing?.data) as IMdsUpdrsIV & IControlProps;
  const mdsUpdrsIVLocales = mdsUpdrsIV.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const mainField = field.split('.')[0];
    const deeperField = field.split('.')?.[1];
    const value = Object.values(values)[0];
    switch (mainField) {
      case 'timeWithDyskinesias':
      case 'timeInOffState':
      case 'painfulOffStateDystonia': {
        const hoursAwakeAffectedField = mainField === 'timeWithDyskinesias' ? 'timeInOffState' : 'timeWithDyskinesias';
        const hoursOffAffectedField =
          mainField === 'painfulOffStateDystonia' ? 'timeInOffState' : 'painfulOffStateDystonia';
        setEditingData?.({
          ...mdsUpdrsIVData,
          [mainField]: {
            ...mdsUpdrsIVData[mainField],
            [deeperField]: value === 'UR' ? value : parseInt(`${value}`),
          },
          ...(deeperField === 'hoursAwake'
            ? { [hoursAwakeAffectedField]: { ...mdsUpdrsIVData[hoursAwakeAffectedField], [deeperField]: value } }
            : []),
          ...(deeperField === 'hoursOff'
            ? { [hoursOffAffectedField]: { ...mdsUpdrsIVData[hoursOffAffectedField], [deeperField]: value } }
            : []),
        });
        break;
      }
      default: {
        setEditingData?.({
          ...mdsUpdrsIVData,
          [field]: mainField === 'date' || value === 'UR' ? value : parseInt(`${value}`),
        });
        break;
      }
    }
  };

  React.useEffect(() => {
    if (
      editing &&
      mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake &&
      mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours
    ) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.timeWithDyskinesias.dyskinesiaHours,
          mdsUpdrsIVData.timeWithDyskinesias.hoursAwake,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeWithDyskinesias: { ...mdsUpdrsIVData.timeWithDyskinesias, dyskinesiaPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake, mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours]);

  React.useEffect(() => {
    if (editing && mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage);
      if (newScore !== mdsUpdrsIVData?.timeWithDyskinesias?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeWithDyskinesias: { ...mdsUpdrsIVData.timeWithDyskinesias, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage]);

  React.useEffect(() => {
    if (editing && mdsUpdrsIVData?.timeInOffState?.hoursAwake && mdsUpdrsIVData?.timeInOffState?.hoursOff) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.timeInOffState.hoursOff,
          mdsUpdrsIVData.timeInOffState.hoursAwake,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeInOffState: { ...mdsUpdrsIVData.timeInOffState, offPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeInOffState?.hoursAwake, mdsUpdrsIVData?.timeInOffState?.hoursOff]);

  React.useEffect(() => {
    if (editing && mdsUpdrsIVData?.timeInOffState?.offPercentage) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData?.timeInOffState?.offPercentage);
      if (newScore !== mdsUpdrsIVData?.timeInOffState?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          timeInOffState: { ...mdsUpdrsIVData.timeInOffState, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.timeInOffState?.offPercentage]);

  React.useEffect(() => {
    if (
      editing &&
      mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff &&
      mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia
    ) {
      const percentage = Math.round(
        mdsUpdrsIV.calculators.calcRatioPercentage(
          mdsUpdrsIVData.painfulOffStateDystonia.hoursOffDystonia,
          mdsUpdrsIVData.painfulOffStateDystonia.hoursOff,
        ),
      );
      if (percentage !== mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          painfulOffStateDystonia: { ...mdsUpdrsIVData.painfulOffStateDystonia, offDystoniaPercentage: percentage },
        });
      }
    }
  }, [mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff, mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia]);

  React.useEffect(() => {
    if (editing && mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage) {
      const newScore = getMdsUpdrsIVScore(mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage);
      if (newScore !== mdsUpdrsIVData?.painfulOffStateDystonia?.value) {
        setEditingData?.({
          ...mdsUpdrsIVData,
          painfulOffStateDystonia: { ...mdsUpdrsIVData.painfulOffStateDystonia, value: newScore },
        });
      }
    }
  }, [mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage]);

  // Track page width and breakpoints
  const mdOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down(1401));

  return (
    <>
      <div>
        <p>
          <span>{mdsUpdrsIVLocales[useLocale]['instructions'].split('"UR"')?.[0]}</span>
          <span style={{ fontWeight: 600 }}>{'"UR"'}</span>
          <span>{mdsUpdrsIVLocales[useLocale]['instructions'].split('"UR"')?.[1]}</span>
        </p>
        <p>
          <span>
            {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[0]}
            {': '}
            {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[1]}
            {': '}
            <br />
            {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[2].split('”')?.[0]}
            {'“'}
            {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[2].split('”')?.[1]}
            {'“'}
            {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[2].split('”')?.[2]}
            {'“ '}
            <span style={{ textDecoration: 'underline' }}>
              {mdsUpdrsIVLocales[useLocale]['instructions2'].split(':')?.[2].split('”')?.[3]}
            </span>
          </span>
        </p>
        <p>
          <span>
            {mdsUpdrsIVLocales[useLocale]['instructions3']?.split(':')?.[0]}
            {': '}
            {mdsUpdrsIVLocales[useLocale]['instructions3']?.split(':')?.[1]}
            {': '}
            <br />
            {mdsUpdrsIVLocales[useLocale]['instructions3']?.split(':')?.[2]}
          </span>
        </p>
        <p>
          <span>
            {mdsUpdrsIVLocales[useLocale]['instructions4'].split(':')?.[0]}
            {': '}
            {mdsUpdrsIVLocales[useLocale]['instructions4'].split(':')?.[1]}
            {': '}
            <br />
            {mdsUpdrsIVLocales[useLocale]['instructions4'].split(':')?.[2]}
          </span>
        </p>
        <p>
          <span>{mdsUpdrsIVLocales[useLocale]['instructions5']}</span>
        </p>
        <p>
          <span>{mdsUpdrsIVLocales[useLocale]['instructions6']}</span>
        </p>
      </div>

      <BlockWrapper
        title={viewing ? formatPartialDate(mdsUpdrsIVData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'mdsUpdrsIV', data: mdsUpdrsIVData });
                  },
                },
              ]
            : undefined
        }
      >
        <StyledInfo mdOrSmaller={mdOrSmaller}>
          <p>{mdsUpdrsIVLocales[useLocale]['mdsPermission']}</p>
        </StyledInfo>
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: mdsUpdrsIVData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormSection header={mdsUpdrsIVLocales[useLocale]['categoryA']} headerFormat={false}>
          <StyledQuestionAndInfo field={'timeWithDyskinesias'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeWithDyskinesiasHoursAwake']} formatTitle={false}>
            <InputHandler
              name="timeWithDyskinesias.hoursAwake"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake}
              formData={{
                document: { ['timeWithDyskinesias.hoursAwake']: mdsUpdrsIVData?.timeWithDyskinesias?.hoursAwake },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeWithDyskinesiasDyskinesiaHours']} formatTitle={false}>
            <InputHandler
              name="timeWithDyskinesias.dyskinesiaHours"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours}
              formData={{
                document: {
                  ['timeWithDyskinesias.dyskinesiaHours']: mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaHours,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeWithDyskinesiasDyskinesiaPercentage']} formatTitle={false}>
            <InputHandler
              name="timeWithDyskinesias.dyskinesiaPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage}
              formData={{
                document: {
                  ['timeWithDyskinesias.dyskinesiaPercentage']:
                    mdsUpdrsIVData?.timeWithDyskinesias?.dyskinesiaPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="timeWithDyskinesias.value"
              type="Radio"
              value={mdsUpdrsIVData?.timeWithDyskinesias?.value}
              formData={{
                document: { ['timeWithDyskinesias.value']: mdsUpdrsIVData?.timeWithDyskinesias?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'timeWithDyskinesias', mdsUpdrsIVLocales[useLocale])}
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'functionalImpactOfDyskinesias'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="functionalImpactOfDyskinesias"
              type="Radio"
              formData={{
                document: { functionalImpactOfDyskinesias: mdsUpdrsIVData?.functionalImpactOfDyskinesias },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'functionalImpactOfDyskinesias', mdsUpdrsIVLocales[useLocale])}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
        <FormSection header={mdsUpdrsIVLocales[useLocale]['categoryB']} headerFormat={false}>
          <StyledQuestionAndInfo field={'timeInOffState'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeInOffStateHoursAwake']} formatTitle={false}>
            <InputHandler
              name="timeInOffState.hoursAwake"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.hoursAwake}
              formData={{
                document: { ['timeInOffState.hoursAwake']: mdsUpdrsIVData?.timeInOffState?.hoursAwake },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeInOffStateHoursOff']} formatTitle={false}>
            <InputHandler
              name="timeInOffState.hoursOff"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.hoursOff}
              formData={{
                document: {
                  ['timeInOffState.hoursOff']: mdsUpdrsIVData?.timeInOffState?.hoursOff,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['timeInOffStateOffPercentage']} formatTitle={false}>
            <InputHandler
              name="timeInOffState.offPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.timeInOffState?.offPercentage}
              formData={{
                document: {
                  ['timeInOffState.offPercentage']: mdsUpdrsIVData?.timeInOffState?.offPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="timeInOffState.value"
              type="Radio"
              value={mdsUpdrsIVData?.timeInOffState?.value}
              formData={{
                document: { ['timeInOffState.value']: mdsUpdrsIVData?.timeInOffState?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'timeInOffState', mdsUpdrsIVLocales[useLocale])}
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'functionalImpactOfFluctuations'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="functionalImpactOfFluctuations"
              type="Radio"
              formData={{
                document: { functionalImpactOfFluctuations: mdsUpdrsIVData?.functionalImpactOfFluctuations },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) =>
                optionFormatter(o, 'functionalImpactOfFluctuations', mdsUpdrsIVLocales[useLocale])
              }
              editing={!!editing}
            />
          </FormRow>
          <StyledQuestionAndInfo field={'complexityOfMotorFluctuations'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="complexityOfMotorFluctuations"
              type="Radio"
              formData={{
                document: { complexityOfMotorFluctuations: mdsUpdrsIVData?.complexityOfMotorFluctuations },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'complexityOfMotorFluctuations', mdsUpdrsIVLocales[useLocale])}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
        <FormSection header={mdsUpdrsIVLocales[useLocale]['categoryC']} headerFormat={false}>
          <StyledQuestionAndInfo field={'painfulOffStateDystonia'} locales={mdsUpdrsIVLocales[useLocale]} />
          <FormRow title={mdsUpdrsIVLocales[useLocale]['painfulOffStateDystoniaHoursOff']} formatTitle={false}>
            <InputHandler
              name="painfulOffStateDystonia.hoursOff"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff}
              formData={{
                document: { ['timeWithDyskinesias.hoursOff']: mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOff },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['painfulOffStateDystoniaHoursOffDystonia']} formatTitle={false}>
            <InputHandler
              name="painfulOffStateDystonia.hoursOffDystonia"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia}
              formData={{
                document: {
                  ['painfulOffStateDystonia.hoursOffDystonia']:
                    mdsUpdrsIVData?.painfulOffStateDystonia?.hoursOffDystonia,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow
            title={mdsUpdrsIVLocales[useLocale]['painfulOffStateDystoniaOffDystoniaPercentage']}
            formatTitle={false}
          >
            <InputHandler
              name="painfulOffStateDystonia.offDystoniaPercentage"
              type="NumberField"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage}
              formData={{
                document: {
                  ['painfulOffStateDystonia.offDystoniaPercentage']:
                    mdsUpdrsIVData?.painfulOffStateDystonia?.offDystoniaPercentage,
                },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
          <FormRow title={mdsUpdrsIVLocales[useLocale]['score']} formatTitle={false}>
            <InputHandler
              name="painfulOffStateDystonia.value"
              type="Radio"
              value={mdsUpdrsIVData?.painfulOffStateDystonia?.value}
              formData={{
                document: { ['painfulOffStateDystonia.value']: mdsUpdrsIVData?.painfulOffStateDystonia?.value },
                onChange: onChangeSurveyForm,
              }}
              options={[0, 1, 2, 3, 4, 'UR']}
              optionFormatter={(o) => optionFormatter(o, 'painfulOffStateDystonia', mdsUpdrsIVLocales[useLocale])}
              editing={!!editing}
            />
          </FormRow>
        </FormSection>
      </BlockWrapper>
      <StyledInfo mdOrSmaller={mdOrSmaller}>
        <p>{mdsUpdrsIVLocales[useLocale]['copyright']}</p>
      </StyledInfo>
    </>
  );
};
